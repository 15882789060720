<div class="container mt-menu">

    <h1>Privacy Policy</h1>

    <div class="content-text">
        <p>Privacy policy has been fulfilled to serve users who have bothered with the online use of their personal identifiable information. PII described in U.S. privacy law and security of information is information that can be used either alone or with other information to identify, contact, or locate a single person, or to identify an individual in context. To obtain a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website, please read our privacy policy carefully.</p>
    
    <p>Which personal data are we gathering from the people who visit our website or app? As appropriate, you may be asked to enter your name, email address and other details when ordering or registering on our site to help you with your experience.</p>
    
    <h4>When do we gather data?</h4>
    <p>When you register on our website, we collect information from you, subscribe to a newsletter, fill out a form, open a support ticket or enter information on our website.</p>
    
    <p>Give us feedback regarding our products or services</p>
    
    <h4>How do we use your details?</h4>
    <p>When you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website or use certain other features of the site, we may use the information we collect from you:</p>
    
    <p>To customize your experience and enable us to deliver the type of content and product offerings you're most interested in.</p>
    <p>To improve our website with a view to serving you better.</p>
    <p>To enable us to serve you better in responding to your customer service requests. </p>
    <p>To send regular emails about your order, or other products and services. </p>
    <p>To follow up on communications with them (live chat, email or telephone inquiries)</p>
    
    <h4>How do we protect your data?</h4>
    <p>We don't use PCI criteria for vulnerability testing and /or testing.</p>
    
    <p>We only give information and articles. We never ask for numbers on credit cards.</p>
    
    <p>We use Malware Scanning regularly. </p>
    
    <p>Your personal information is stored within encrypted networks and is accessible only by a small number of individuals who have exclusive access rights to these systems and are allowed to preserve confidentiality of the information. Additionally, all the sensitive / credit information that you provide is encrypted using Protected Socket Layer (SSL) technology.</p>
    
    <p>When a user enters, submits, or accesses their details, we enforce a set of security measures to ensure the protection of your personal information.</p>
    
    <p>All transactions are processed by a provider of gateways and are not stored or processed on our servers.</p>
    
    <h4>Are we using 'cookies'?</h4>
    <p>Yeah. Cookies are small files that a website or its service provider transfers to the hard drive of your computer through your web browser (if you permit) which allows the systems of the website or service provider to recognize your browser and capture and remember certain information. For example, we use cookies to help us remember the items in your watch history and to process them. They are also used to help us understand your interests based on past or current site operation, allowing us to provide you with enhanced services. We also use cookies to help us compile aggregate data on website traffic and website interaction so that in the future we can offer better website experience and tools.</p>
    
    <h4>Cookies are used to:</h4>
    <p>Understand the user preferences and save them for future visits. </p>
    <p>Keep track of adverts. </p>
    <p>To offer better site experiences and tools in the future, compile aggregate data about site traffic and site interactions.</p>
    <p>We will also use trusted services of third parties that monitor this information on our behalf. </p>
    <p>We will also use trusted services of third parties that monitor this information on our behalf.</p>
    <p>Each time a cookie is sent you can choose to have your machine alert you or you can choose to turn off all cookies. You do this by settings on your computer. See your browser's Help Menu to learn the correct way to modify your cookies, because the browser is a bit different. </p>
    
    <h4>Where users in their browser disable cookies:</h4>
    <p>When you turn off cookies some of the site's features will be turned off. </p>
    
    <h4>Disclosure by third parties</h4>
    <p>We will not sell, exchange or otherwise pass your Personally Identifiable Information to third parties unless we provide an advance warning to users. This does not include website hosting partners and other parties who assist us in the operation of our website, conducting our business or serving our users as long as those parties agree to confidentiality with this information. We can also release information when it's necessary to comply with the law, enforce our website policies, or protect the rights, properties, or protection of ours or others.
    However, visitor information that is not personally identifiable may be provided for marketing, advertising, or other uses by other parties.
    </p>
    
    <h4>Links provided by third parties</h4>
    <p>We can sometimes include or sell third party products or services on our website, at our discretion. Such third party websites are subject to different and independent privacy policies. Therefore we have no liability for the content and activities of these linked sites. Nevertheless, we try to preserve our site 's reputation and welcome any input on these pages. </p>
    
    <h4>Google</h4>
    <p>The advertisement criteria of Google can be outlined using the Google Advertising Principles. They are put in order to provide the users with a good experience. https:/support.google.com/response/adwordspolicy/1316548? Hl= en Use Google AdSense  </p>
    
    <h4>Our website is advertising.</h4>
    
    <p>As a third-party vendor Google uses cookies to display ads on our pages. Based on previous visits to our website and other websites, Google's use of the DART cookie allows it to serve ads to our users. Users may opt-out of using the DART cookie by visiting the privacy policy of the Google Ad and Content Network.</p>
    
    <h4>We made the following implementations: </h4>
    <p>Web AdSense patent</p>
    <p>Google Display Reports on Network Impression</p>
    <p>Reporting Demographics and Awareness</p>
    <p>DoubleClick Integration into the Platform</p>
    <p>Together with third-party vendors such as Google, we use first-party cookies (such as Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers to collect data on user experiences with ad impressions and other functions related to our website. </p>
    
    <h4>Opting out:</h4>
    <p>Users can use the Google Ad Preferences page to set expectations on how Google advertises to you. Alternatively, you can opt-out by visiting the Opt-out page of the Network Advertising Program, or by using the Google Analytics Opt-out browser. </p>
    
    <h4>California Online Privacy Protection Act</h4>
    <p>CalOPPA is the nation's first state law to mandate that commercial websites and internet services publish a privacy policy. The reach of the law extends far beyond California to require any person or company in the U.S. (and possibly the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on their website stating exactly the information being collected and the individuals or companies with whom it is shared. For more information see:http:/consumercal.org/california-online-privacy-act-caloppa/#sthash.0FdRbT51.dpuf</p>
    
    <p>We are agreeing to the following according to CalOPPA: </p>
    <p>Users will anonymously access our web site. </p>
    
    <p>When this privacy policy is established, after entering our website, we will add a link to it on our homepage or as a minimum, on the first significant page. </p>
    
    <p>Our link to the Privacy Policy includes the word 'Privacy,' which can be easily found on the above tab. </p>
    
    <h4>Any changes to Privacy Policy will be reported to you:</h4>
    
    <p>On our Data Management Page</p>
    <p>Might change your personal data:</p>
    
    <h4>Sign in to your account</h4>
    <p>By chating with us or sending us a ticket for support</p>
    <h4>How does our platform treat Signals Do Not Track? </h4>
    <p>We honor Don't watch signals and don't watch, plant cookies or use ads when a browser system Do Not Track (DNT) is in operation. </p>
    
    <h4>Is our platform allowing behavioral monitoring by third parties? </h4>
    <p>It is also important to note that we allow conduct tracking by third parties</p>
    
    <h4>COPPA (Children Online Privacy Protection Act) </h4>
    <p>The Children's Online Privacy Protection Act ( COPPA) sets parents in control when it comes to collecting personal information from children under the age of 13. The Federal Trade Commission, the U.S. consumer protection agency, enforces the COPPA rule, which outlines what website operators and online services must do to protect the privacy and security of children online.</p>
    <p>We 're not specifically marketing to children under the age of 13. </p>
    
    <p>Do we allow third parties to collect PII from children under 13 including ad networks or plug-ins? </p>
    
    <h4>Fair data practices</h4>
    <p>The Fair Information Practices Principles form the backbone of U.S. privacy law, and the concepts it includes have played an important role in the development of data protection laws around the world. Understanding the principles of Fair Information Practice and how they should be applied is crucial to complying with the different privacy laws which protect personal information. </p>
    
    <p>To be consistent with Fair Information Practices we will take the following responsive action should there be a data breach: </p>
    <p>We'll notify you by email</p>
    
    <p>Inside 5 workdays</p>
    <p>We'll alert users via in-site notification</p>
    
    <p>Within 3 workdays</p>
    
    <p>We also agree with the Individual Redress Principle which requires individuals to have the right to legally pursue enforceable rights against data collectors and processors who are not adhering to the law. This concept includes not only that individuals have enforceable data access rights, but also that individuals have recourse to court or government authorities to investigateand/or prosecute data processor non-compliance. </p>
    
    <h4>CAN SPAM Act</h4>
    <p>The CAN-SPAM Act is a statute that sets guidelines for commercial email, sets standards for commercial communications, grants users the power to avoid emails from being sent to them, and imposes strict penalties for infringements.</p>
    
    <p>We'll send your email address to: </p>
    <p>Send info, answer inquiries,and/or other requests or questions</p>
    <p>To be consistent with CANSPAM, we agree to: </p>
    <p>Do not use incorrect or misleading topics or email addresses. </p>
    <p>In a fair way, define the message as advertising. </p>
    <p>Include the physical address of the headquarters for our company or location. </p>
    <p>Track the enforcement services of third party email marketing, if one is used. </p>
    <p>Allow users to unsubscribe using the link at the bottom of every email address. </p>
    <p>If you wish to unsubscribe from receiving future emails at any time , please email us at
    Ignore any email at the bottom of the directions. </p>
    <p>And we will delete you from ALL of the correspondence immediately. </p>
    </div>
</div>
