import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeaturedSlider } from '../models/featured-slider.model';
import { Movies } from '../models/movies.model';

const enum endpoint {
  latest = '/movie/latest',
  now_playing = '/movie/now_playing',
  popular = '/movie/popular',
  top_rated = '/movie/top_rated',
  upcoming = '/movie/upcoming',
  trending = '/trending/all/week',
  originals = '/discover/tv',
}

@Injectable({
  providedIn: 'root',
})
export class MovieService {
  private URL = environment.URL;
  public loginMenu: string = '';
  public loginMenuChanges: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.loginMenu);
  public userData = null;
  public userDataChanges: BehaviorSubject<any> = new BehaviorSubject<string>(
    this.userData
  );

  constructor(private http: HttpClient) {}

  getTokenHeader(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.userData) {
        let tokenHeader = new HttpHeaders({
          Authorization: 'Bearer ' + this.userData.token,
        });
        tokenHeader.append('Access-Control-Allow-Origin', '*');
        tokenHeader.append('Access-Control-Allow-Headers', 'Content-Type');
        tokenHeader.append(
          'Access-Control-Allow-Methods',
          'GET,POST,PUT,DELETE,OPTIONS'
        );
        tokenHeader.append('Accept', 'GET,POST,PUT,DELETE,OPTIONS');
        tokenHeader.append('Content-Type', 'application/json');
        resolve(tokenHeader);
      } else {
        let tokenHeader = new HttpHeaders();
        tokenHeader.append('Access-Control-Allow-Origin', '*');
        tokenHeader.append('Access-Control-Allow-Headers', 'Content-Type');
        tokenHeader.append(
          'Access-Control-Allow-Methods',
          'GET,POST,PUT,DELETE,OPTIONS'
        );
        tokenHeader.append('Accept', 'GET,POST,PUT,DELETE,OPTIONS');
        tokenHeader.append('Content-Type', 'application/json');
        resolve(tokenHeader);
      }
    });
  }

  getFeaturedSlider(): Observable<any> {
    return this.http.get<any>(`${this.URL}/homeplaylist`, {});
  }

  getSingleVideoForVisitor(data): Observable<any> {
    return new Observable((observer) => {
      this.getTokenHeader()
        .then((headers) => {
          return this.http
            .get(`${this.URL}/getContent/` + data, { headers: headers })
            .subscribe((res) => {
              observer.next(res);
              observer.complete();
            });
        })
        .catch((error: any) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
      this.getTokenHeader()
        .then((headers) => {
          return this.http
            .post(`${this.URL}/logout`, {}, { headers: headers })
            .subscribe((res) => {
              observer.next(res);
              observer.complete();
            });
        })
        .catch((error: any) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  searchContent(data): Observable<any> {
    return new Observable(observer => {
      this.getTokenHeader()
        .then(headers => {
          return this.http.post(`${this.URL}/search`,data, {headers: headers} )
              .subscribe(res => {
                observer.next(res);
                observer.complete();
              })
        })
        .catch(( error: any ) => {
          observer.error(error);
          observer.complete();
        });
    });
  }


  checkLogin() {
    var localData = JSON.parse(localStorage.getItem('margalla-profile'));

    if (localData) {
      this.triggerStatusChange(localData);
    } else {
      this.triggerStatusChange(null);
    }
  }

  login(data): Observable<any> {
    return new Observable((observer) => {
      return this.http.post(this.URL + '/login', data).subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  signUp(email: string, password: string) {
    return;
  }



  triggerLogin() {
    this.loginMenuChanges.next('open');
  }

  triggerStatusChange(userData) {
    this.userData = userData;
    this.userDataChanges.next(userData);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(
      `${this.URL}/getCategoryContent`,
      {}
    );
  }
}
