import { Component, Input, OnInit } from '@angular/core';
import { PlaylistsEntity } from 'src/app/models/featured-slider.model';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  @Input() sliderConfig;
  @Input() playlists: PlaylistsEntity;
  @Input() title: string;



  constructor() { }

  ngOnInit(): void {
  }

}
