<div class="featured-section">
    <ngx-slick-carousel [config]="sliderConfigLarge" #slickModalLarge="slick-carousel"  class="carousel sliderConfigLarge">
                        
        <div class="slider-single" *ngFor="let m of featuredSlider" ngxSlickItem>
            <div class="slider-large">
                <div class="slider-large-image">
                    <img src="{{m.content.poster}}" alt="{{m.title}}">
                </div>
                <div class="titleBtn">
                    <h3>{{m.content.title}}</h3>
    
                    <button class="play-button" [routerLink]="['/video/', m.content_id ]">
                        <mat-icon>arrow_right</mat-icon>
                        <span>Play</span>
                    </button>
                </div>
                <div class="overlay-bg-image"></div>
            </div>
        </div>
    </ngx-slick-carousel>
    

    <div class="featured-bottom-section">
        <ngx-slick-carousel [config]="sliderConfigSmall" #slickModalSmall="slick-carousel" class="carousel sliderConfigSmall">
                            
            <div class="slider-single" *ngFor="let m of featuredSlider;let i = index" ngxSlickItem>
                <div class="slider-small">
                <img src="{{m.content.thumbnail}}" (click)="slickModalSmall.slickGoTo(i);slickModalLarge.slickGoTo(i);activeSlide = i" [class]="i == activeSlide ? 'thumbnailActive' : ''"  alt="{{m.title}}">
                 </div>
            </div>
        </ngx-slick-carousel>
    </div>
</div>