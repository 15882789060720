import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-video-slider',
  templateUrl: './single-video-slider.component.html',
  styleUrls: ['./single-video-slider.component.scss']
})
export class SingleVideoSliderComponent implements OnInit {

  @Input() sliderConfig;
  @Input() playlist;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
