<div class="movie">
    <div class="movie__images">
        <div class="thumbnails">
            <ngx-slick-carousel [config]="sliderConfig" class="carousel">
                    <div class="slick-item" *ngFor="let m of playlist" ngxSlickItem>
                        <div class="single-image"  [routerLink]="['/video/', m.id ? m.id : m.pivot.content_id ]" >
                            <div class="single-image-inside">
                                <img src="{{m.thumbnail}}" alt="{{m.title}}">
                            </div>
                        </div>
                    </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
