<div class="col-sm-auto my-menu">
  <div class="row">
    <div class="col-lg-2">
      <a
        href="/"
        class="d-block p-3 link-dark text-decoration-none"
        title=""
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        data-bs-original-title="Icon-only"
      >
        <img src="assets/images/logo.png" alt="logo" />
      </a>
    </div>

    <div class="col-lg-8">
      <ul
        class="nav nav-pills nav-flush flex-row flex-nowrap mb-auto mx-auto text-center align-items-center my-menu-all"
      >

        <li>
          <a
            (click)="goto('/')"
            class="nav-link py-3 px-2"
            title=""
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-original-title="Home"
          >
            HOME
          </a>
        </li>
        <li>
          <a
            (click)="goto('/category')"
            class="nav-link py-3 px-2"
            title=""
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-original-title="Moives"
          >
            CATEGORIES
          </a>
        </li>

        <!-- <li>
          <a
            (click)="goto('/search')"
            class="nav-link py-3 px-2"
            title=""
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-original-title="Search"
          >
           LIVE STREAMS
          </a>
        </li> -->


      </ul>
    </div>

    <div class="col-lg-2">
      <ul
      class="nav nav-pills nav-flush flex-row flex-nowrap mb-auto mx-auto text-center align-items-center my-menu-all"
    >
      <li>
      <a
      (click)="goto('/search')"
      class="nav-link py-3 px-2"
      title=""
      data-bs-toggle="tooltip"
      data-bs-placement="right"
      data-bs-original-title="Search"
    >
      <mat-icon>search</mat-icon>
    </a>
  </li>
    <li *ngIf="isLogged" class="menu-image">
      <img
        mat-button
        [matMenuTriggerFor]="menu"
        src="assets/images/profile.jpg"
        alt="avatar"
      />

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </li>
    <li *ngIf="!isLogged">
      <a
        (click)="openLogin()"
        class="nav-link py-3 px-2"
        title=""
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        data-bs-original-title="Login"
      >
        <mat-icon>person</mat-icon>
      </a>
    </li>
    </ul>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body">
    <div class="top-button">
      <button
        type="button"
        class="no-btn"
        (click)="closeLoginWindow()"
        aria-label="Close"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-4 offset-md-4">
          <h1 class="text-center login-title">Sign in to continue</h1>
          <div class="account-wall">
            <img
              class="profile-img"
              src="assets/images/logo.png"
              alt=""
            />
            <form
              class="form-signin"
              (ngSubmit)="loginuser(signinForm.value)"
              [formGroup]="signinForm"
            >
              <input
                formControlName="email"
                type="email"
                name="email"
                class="form-control mb-2"
                placeholder="Email"
                required
                autofocus
              />
              <input
                formControlName="password"
                name="password"
                type="password"
                class="form-control"
                placeholder="Password"
                required
              />
              <p class="newButton" *ngIf="showerrorBlock">* {{ errorText }}</p>
              <button
                class="btn btn-lg btn-primary btn-block"
                type="submit"
                [disabled]="!signinForm.valid"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
