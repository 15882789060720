import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SingleScreenComponent } from './components/single-screen/single-screen.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { SearchComponent } from './components/search/search.component';
import { CategoryComponent } from './components/category/category.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'video/:id',
    component: SingleScreenComponent,
  },
  {
    path: 'category',
    component: CategoryComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
