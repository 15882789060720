import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MovieService } from 'src/app/services/movie.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Campaign } from 'src/app/models/campaign.model';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  sticky = false;
  showerrorBlock = false;
  showerror = false;
  showNoUserError = false;
  showPasswordError = false;
  isLogged = false;
  showAlreadyExistEroor = false;
  wentWrong = false;
  showSignin = true;
  profileimg = '';
  modalRef: BsModalRef;
  @ViewChild('template') template;

  textPhoneNumber = '';
  showWrongNumber = false;
  sendCode = false;
  dialCode = '92';
  showPhoneEnter = true;
  numberEmpty = false;
  tryagain = false;
  showInvalidCode = false;
  fullNumber = '';
  windowRef: any;
  verificationID = '';
  codeText = '';
  errorText = '';


  campaign : Observable<Campaign>;
  campaignValue: any;

  public signinForm = new FormGroup({
    email: new FormControl('',  [Validators.required, Validators.email]),
    password: new FormControl('',  Validators.required),

  });

  public signUpForm = new FormGroup({
    username: new FormControl('',  Validators.required),
    email: new FormControl('',  [Validators.required, Validators.email]),
    password: new FormControl('',  Validators.required),
  });

  public passrestForm = new FormGroup({
    email: new FormControl('',  [Validators.required, Validators.email]),
  });

  constructor(
    private movie: MovieService,
    private modalService: BsModalService,
    public router: Router,
    private cdRef:ChangeDetectorRef,
    private win: WindowService,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) { }


  ngOnInit(): void {

    this.movie.loginMenuChanges.subscribe(x => {
      if(x == 'open'){
          this.openLogin();
      }
    })

    if (isPlatformBrowser(this.platformId)) {

      this.movie.checkLogin();

      this.movie.userDataChanges.subscribe(x => {
         if(x){
           this.isLogged = true;
         }else{
          this.isLogged = false;
         }
      })

    }
  }


  onCountryChange(e){
    this.dialCode = e.dialCode;
  }


  loginuser(formData: FormData){
    this.showerrorBlock = false;

    var data = {
      email : formData['email'],
      password : formData['password'],
      device_name: 'web'
    }
    this.movie.login(data).subscribe((res) => {

        if(res.code == 200){
          localStorage.setItem('margalla-profile', JSON.stringify(res.data));
          this.signinForm.reset();
          this.movie.triggerStatusChange(res.data);
          this.modalRef.hide();
        }else{
          this.showerrorBlock = true;
          this.errorText = res.message;
        }
    
    },err => {
      this.showerrorBlock = true;
      this.errorText = err.error.message;
      console.log(err)
    })

  }

  openLogin()
  {
    this.modalRef = this.modalService.show(this.template);
        this.modalRef.setClass("full-screen-modal");
        
       
  }

  logout()
  {
    this.movie.logout().subscribe(res => {
    })
    localStorage.removeItem("margalla-profile");
    this.movie.triggerStatusChange(null);
  }

  showSignupWindow()
  {
    this.showSignin = false;
    this.signinForm.reset();
  }

  showSignupInWindow()
  {
    this.showSignin = true;
    this.signUpForm.reset();
  }

  goto(link){
    this.router.navigate([link]);
  }


  signupuser(formData: FormData){
    this.wentWrong = false;
        this.showAlreadyExistEroor = false;
    // this.movie.signUp(formData['email'], formData['password']).then(res => {
    //         // this.afAuth.currentUser.sendEmailVerification().then(res => {
              
    //         // })

    //        var data = {
    //           "name": formData['username'],
    //           'source' : this.campaignValue ? this.campaignValue[0].source ? this.campaignValue[0].source : 'direct' : 'direct',
    //           'campaign_slug' : this.campaignValue ? this.campaignValue[0].campaign_slug ? this.campaignValue[0].campaign_slug : null : null,
    //           'referrer_id': this.campaignValue ? this.campaignValue[0].referrer_id ? this.campaignValue[0].referrer_id : null : null,
    //           'page': window.location.href,
    //           'platform': 'web'
    //           } 

    //         this.movie.makeProfile(data).subscribe(res => {
    //           this.signinForm.reset();
    //           this.signUpForm.reset();
    //           localStorage.setItem('dew-profile', JSON.stringify(res.data));
    //           this.movie.triggerStatusChange();
    //           this.modalRef.hide();
    //         });
            
    // })
    // .catch(err => {
    //   if(err.code == 'auth/email-already-in-use'){
    //     this.showAlreadyExistEroor = true;
    //     this.wentWrong = false;
    //     this.cdRef.detectChanges();
    //   }else if(err.code == 'auth/account-exists-with-different-credential'){
    //     this.showAlreadyExistEroor = true;
    //     this.wentWrong = false;
    //     this.cdRef.detectChanges();
    //   }else{
    //     this.wentWrong = true;
    //     this.showAlreadyExistEroor = false;
    //     this.cdRef.detectChanges();
    //   }
    // })
   
    // this.router.navigate(["/profile"])
  }




  closeLoginWindow()
  {
    this.tryagain = false;
    this.sendCode = false;
    this.showPhoneEnter = true;
    this.showWrongNumber = false;
    this.numberEmpty = false;
    this.showInvalidCode = false;
    this.codeText = '';
    this.fullNumber = '';
    this.textPhoneNumber = '';
    this.verificationID = '';
    this.errorText = '';
    this.showerrorBlock = false;
    this.modalRef.hide();
  }

  goBackBtn()
  {
    this.tryagain = false;
    this.sendCode = false;
    this.showPhoneEnter = true;
    this.showWrongNumber = false;
    this.numberEmpty = false;
    this.showInvalidCode = false;
    this.codeText = '';
    this.fullNumber = '';
    this.textPhoneNumber = '';
    this.verificationID = '';
    this.errorText = '';
    this.showerrorBlock = false;
  }

}
